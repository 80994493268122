/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import Image from '../../util/Image';

const Grid = ({ data }) => (
  <>
    { data.map(({ grid }, i) => (
      <section className="grid-portfolio" key={`grid_${i}`}>
        { grid.map((image) => (
          <Image
            filename={image.path}
            className={image.styling}
            alt={image.alt}
            key={image.key}
          />
        ))}
      </section>
    ))}
  </>
);

export default Grid;

Grid.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      grid: PropTypes.arrayOf(
        PropTypes.shape({
          key: PropTypes.string,
          alt: PropTypes.string,
          path: PropTypes.string,
          styling: PropTypes.string,
        }).isRequired,
      ).isRequired,
    }).isRequired,
  ).isRequired,
};
