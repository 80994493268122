import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import Layout from '../layouts/main/Layout';
import Grid from '../layouts/portfolio/Grid';
import data from '../assets/images/illustrations/illustrations.json';
import SEO from '../util/SEO/SEO';

const illustrations = ({ ...props }) => {
  const intl = useIntl();

  const { originalPath } = props.pathContext.intl;
  return (
    <Layout originalPath={originalPath}>
      <SEO
        locale={intl.locale}
        title={intl.formatMessage({ id: 'illustrations.SEOtitle' })}
        description={intl.formatMessage({ id: 'illustrations.SEODescription' })}
        keywords={intl.formatMessage({ id: 'SEOkeywords.keywords' })}
      />
      <Grid data={data} />
    </Layout>
  );
};

export default illustrations;
